import API from "@/api";

export default {
  // 判断是否第一次查询
  selfInfo: () => {
    return API.requestPost(
      {
        auth: true,
        url: "/user/info",
      },
      true
    );
  },
  // 判断是否第一次查询
  checkFirst: (params) => {
    return API.requestGet(
      {
        params,
        auth: true,
        url: "/api/report/hasSelectedReport",
      },
      false
    );
  },
  // 查实名认证
  getVerify: (params) => {
    return API.requestGet(
      { params, url: "/api/common/report/basic/user" },
      false
    );
  },
  // 运营商
  getOperator: (params) => {
    return API.requestGet(
      { params, url: "/api/common/report/third/operator" },
      false
    );
  },
  // 多头借贷
  getDt: (params) => {
    return API.requestGet(
      { params, url: "/api/common/report/third/dt" },
      false
    );
  },
  // 地图定位
  getMapData: (params) => {
    return API.requestGet(
      { params, url: "/api/common/report/basic/loginRecord" },
      false
    );
  },

  //范围人数
  getRangePerson: (params) => {
    return API.requestGet(
      {
        url: "/api/common/report/basic/nearVerifyUser",
        params,
      },
      false
    );
  },
  // 移出黑名单
  deleteBlackUser: (param) => {
    return API.requestPost({
      url: "friend/operationFriendList",
      data: {
        status: 0,
        ...param,
      },
      auth: true,
    });
  },
  // 添加到黑名单
  addBlackUser: (param) => {
    return API.requestPost({
      url: "friend/operationFriendList",
      data: {
        status: -1,
        ...param,
      },
      auth: true,
    });
  },
  // 审核
  check: (params) => {
    return API.requestPost({
      data: params,
      url: "friend/check",
    });
  },
  //用户报告的备注
  remark: (params) => {
    return API.requestPost({
      data: params,
      url: "friend/editMemo",
    });
  },
  //用户报告的备注历史记录
  remarkRecord: (params) => {
    return API.requestPost({
      data: params,
      url: "friend/selectMemoRecord",
    });
  },

  // 发送验证码
  getCode: (params) => {
    return API.requestPost(
      {
        url: "/api/sms/sendSms",
        data: params,
      },
      false
    );
  },
  //创建运营商任务
  operatorBegin: (params) => {
    return API.requestGet(
      {
        url: "/api/common/report/basic/operatorBegin",
        params,
      },
      false
    );
  },
  //获取运营商状态
  operatorStatus: (params) => {
    return API.requestGet(
      {
        url: "/api/common/report/basic/operatorStatus",
        params,
      },
      false
    );
  },
  //填写运营商验证码
  operatorInputSms: (params) => {
    return API.requestGet(
      {
        url: "/api/common/report/basic/operatorInputSms",
        params,
      },
      false
    );
  },
  //获取拒绝原因
  getRefuseOptions: (params) => {
    return API.requestPost(
      {
        url: "/api/v2/basic/queryBasic",
        data: {
          keys: "sys_reject_reason",
        },
      },
      false
    );
  },
  //逾期风险
  getOverdueRisk: (params) => {
    return API.requestGet(
      { url: "/api/common/report/wind/ys-black", params },
      false
    );
  },
  //历史记录报告中， 审核
  auditFriend: (params) => {
    return API.requestPost(
      {
        url: "/api/v3/user/auditFriend",
        data: params,
      },
      false
    );
  },
  // AF报告
  getAfu: (params) => {
    return API.requestGet(
      {
        params,
        url: "/api/common/report/third/afu",
      },
      false
    );
  },
  // 查询对象详情
  detail: (id) => {
    return API.requestGet({
      url: "friend/detail",
      auth: true,
      params: { id },
    });
  },
  // 查询上级商户的信息
  superInfo: (param) => {
    return API.requestPost({
      url: "user/superInfo",
      auth: true,
      param,
    });
  },
  //最新的风控接口
  // AF报告
  commonRisk: (params, type) => {
    // // console.log('params',params)
    // // console.log('type',type)
    return API.requestGet(
      {
        params,
        url: "api/common/report/third/commonRisk/" + type,
      },
      false
    );
  },
};
