import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setArchives (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    }
  },
  actions: {
    getArchives ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setArchives", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'ARCHIVES')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setArchives", {
            provider,
            result_detail: data['ARCHIVES'].data.result_detail,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    list1: state => {
      let data = state.result_detail
      return [{
        name: "逾期机构数",
        value: data.member_count || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "家"
      },
      {
        name: "逾期订单数",
        value: data.order_count || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "笔"
      },
      {
        name: "逾期总金额",
        value: data.debt_amount || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "元"
      },
      {
        name: "近1月共债机构数",
        value: data.current_org_count || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "个"
      },
      {
        name: "近1月共债订单数",
        value: data.current_order_count || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "笔"
      },
      {
        name: "近1月共债订单已还款金额",
        value: data.current_order_amt || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "元"
      },
      {
        name: "近1月共债订单金额",
        value: data.current_order_lend_amt || 0,
        color: "rgba(255, 0, 8, 1)",
        unit: "元"
      }
      ];
    },
    list2: state => {
      let arr = []
      if(state.result_detail && state.result_detail.totaldebt_detail){
        let data= state.result_detail.totaldebt_detail;
        data.map(it => {
          arr.push([{
            name: "共债统计时间范围",
            value: it.totaldebt_date || 0,
            color: "rgba(255, 0, 8, 1)"
          },
          {
            name: "共债机构数",
            value: it.totaldebt_org_count || 0,
            color: "rgba(255, 0, 8, 1)",
            unit: "个"
          },
          {
            name: "共债订单数",
            value: it.totaldebt_order_count || 0,
            color: "rgba(255, 0, 8, 1)",
            unit: "笔"
          },
          {
            name: "共债订单已还款金额",
            value: it.totaldebt_order_amt,
            color: "rgba(255, 0, 8, 1)",
            unit: "元"
          },
          {
            name: "疑似借新还旧",
            value: it.new_or_old=='N'?'否':'是',
            color: "rgba(255, 0, 8, 1)"
          },
          {
            name: "共债订单金额",
            value: it.totaldebt_order_lend_amt || 0,
            color: "rgba(255, 0, 8, 1)",
            unit: "元"
          }
          ])
        })
      }
      return arr;
    }
  }
};
