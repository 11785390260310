export default {
  // 报告评分
  scoreList: state => {
    let apply_report_detail = state.apply_report_detail || {};
    let behavior_report_detail = state.behavior_report_detail || {};
    let current_report_detail = state.current_report_detail || {};
    return [
      {
        name: "申请准入分",
        value: apply_report_detail.A22160001
          ? Number(apply_report_detail.A22160001)
          : 0,
        color: "rgba(64, 158, 255, 1)"
      },
      {
        name: "申请置信度",
        value: apply_report_detail.A22160002
          ? Number(apply_report_detail.A22160002)
          : 0,
        color: "rgba(103, 193, 57, 1)"
      },
      {
        name: "贷款行为分",
        value: behavior_report_detail.B22170001
          ? Number(behavior_report_detail.B22170001)
          : 0,
        color: "rgba(230, 162, 61, 1)"
      },
      {
        name: "额度置信度",
        value: current_report_detail.C22180002
          ? Number(current_report_detail.C22180002)
          : 0,
        color: "rgba(245, 108, 108, 1)"
      }
    ];
  },
  // 查询机构
  searchOrgs: state => {

    let { apply_report_detail = {} } = state;
    return [
      {
        name: "查询机构数",
        num: apply_report_detail.A22160003 || 0,
        unit: "家"
      },
      {
        name: "查询消费金融类机构数",
        num: apply_report_detail.A22160004 || 0,
        unit: "次"
      },
      {
        name: "查询网贷类机构数",
        num: apply_report_detail.A22160005 || 0,
        unit: "家"
      }
    ];
  },
  // 网贷类
  netList: state => {

    let { current_report_detail = {}, behavior_report_detail = {} } = state;
    return [
      {
        name: "已放款贷款笔数",
        num: behavior_report_detail.B22170006 || 0,
        color: "rgba(103, 193, 57, 1)"
      },
      {
        name: "贷款已结清订单数",
        num: behavior_report_detail.B22170052 || 0,
        color: "rgba(103, 193, 57, 1)"
      },
      {
        name: "信用贷款时长",
        num: behavior_report_detail.B22170053 || 0,
        color: "rgba(0, 0, 0, 1)"
      }
    ];
  },
  // 消金类
  financeList: state => {

    let { behavior_report_detail = {} } = state;
    return [
      {
        name: "最近一次贷款放款时间",
        num: behavior_report_detail.B22170054 || 0,
        color: "rgba(103, 193, 57, 1)"
      },
      {
        name: "最近一次履约距今天数",
        num: behavior_report_detail.B22170050 || 0,
        color: "rgba(255, 0, 8, 1)"
      },
      {
        name: "正常还款订单数占贷款总订单数比例",
        num: behavior_report_detail.B22170034 || 0,
        color: "rgba(103, 193, 57, 1)"
      }
    ];
  },
  financeList3: state => {

    let { behavior_report_detail = {} } = state;
    return [
      {
        name: "近6个月累计逾期金额",
        num: behavior_report_detail.B22170031 || 0,
        color: "rgba(255, 0, 8, 1)"
      },
      {
        name: "近12个月累计逾期金额",
        num: behavior_report_detail.B22170032 || 0,
        color: "rgba(255, 0, 8, 1)"
      },
      {
        name: "近24个月累计逾期金额",
        num: behavior_report_detail.B22170033 || 0,
        color: "rgba(255, 0, 8, 1)"
      }
    ];
  },
  financeList4: state => {

    let { behavior_report_detail = {} } = state;
    return [
      {
        name: "近6个月M0+逾期贷款笔数",
        num: behavior_report_detail.B22170025 || 0,
        color: "rgba(255, 0, 8, 1)",
        content: "近6个月逾期在30天以内的有多少笔"
      },
      {
        name: "近12个月M0+逾期贷款笔数",
        num: behavior_report_detail.B22170026 || 0,
        color: "rgba(255, 0, 8, 1)",
        content: "近12个月逾期在30天以内的有多少笔"
      },
      {
        name: "近24个月M0+逾期贷款笔数",
        num: behavior_report_detail.B22170027 || 0,
        color: "rgba(255, 0, 8, 1)",
        content: "近24个月逾期在30天以内的有多少笔"
      }
    ];
  },
  financeList5: state => {

    let { behavior_report_detail = {} } = state;
    return [
      {
        name: "近6个月M1+逾期贷款笔数",
        num: behavior_report_detail.B22170028 || 0,
        color: "rgba(255, 0, 8, 1)",
        content: "近6个月逾期在30天以上60天以内的有多少笔"
      },
      {
        name: "近12个月M1+逾期贷款笔数",
        num: behavior_report_detail.B22170029 || 0,
        color: "rgba(255, 0, 8, 1)",
        content: "近12个月逾期在30天以上60天以内的有多少笔"
      },
      {
        name: "近24个月M1+逾期贷款笔数",
        num: behavior_report_detail.B22170030 || 0,
        color: "rgba(255, 0, 8, 1)",
        content: "近24个月逾期在30天以上60天以内的有多少笔"
      }
    ];
  },
  loanList: state => {

    let { behavior_report_detail = {} } = state;
    return [
      { name: "1k及以下", value: behavior_report_detail.B22170012 || 0 },
      { name: "1-3k", value: behavior_report_detail.B22170013 || 0 },
      { name: "3-10k", value: behavior_report_detail.B22170014 || 0 },
      { name: "1w以上", value: behavior_report_detail.B22170015 || 0 }
    ];
  },
  // 行为雷达详情
  behavior: state => {

    let { behavior_report_detail = {} } = state;
    return [
      {
        name: "贷款笔数",
        oneMonth: behavior_report_detail.B22170002 || "-",
        threeMonth: behavior_report_detail.B22170003 || "-",
        sixMonth: behavior_report_detail.B22170004 || "-",
        oneYear: behavior_report_detail.B22170005 || "-",
        twoYear: behavior_report_detail.B22170006 || "-"
      },
      {
        name: "贷款总金额",
        oneMonth: behavior_report_detail.B22170007 || "-",
        threeMonth: behavior_report_detail.B22170008 || "-",
        sixMonth: behavior_report_detail.B22170009 || "-",
        oneYear: behavior_report_detail.B22170010 || "-",
        twoYear: behavior_report_detail.B22170011 || "-"
      },
      {
        name: "贷款机构数",
        oneMonth: behavior_report_detail.B22170016 || "-",
        threeMonth: behavior_report_detail.B22170017 || "-",
        sixMonth: behavior_report_detail.B22170018 || "-",
        oneYear: behavior_report_detail.B22170019 || "-",
        twoYear: behavior_report_detail.B22170020 || "-"
      },
      // {
      //   name: "消金类贷款机构数",
      //   oneMonth: "-",
      //   threeMonth: "-",
      //   sixMonth: "-",
      //   oneYear: behavior_report_detail.B22170021 || "-",
      //   twoYear: behavior_report_detail.B22170022 || "-"
      // },
      // {
      //   name: "网贷类贷款机构数",
      //   oneMonth: "-",
      //   threeMonth: "-",
      //   sixMonth: "-",
      //   oneYear: behavior_report_detail.B22170023 || "-",
      //   twoYear: behavior_report_detail.B22170024 || "-"
      // },
      // {
      //   name: "M0+逾期贷款笔数",
      //   oneMonth: "-",
      //   threeMonth: "-",
      //   sixMonth: behavior_report_detail.B22170025 || "-",
      //   oneYear: behavior_report_detail.B22170026 || "-",
      //   twoYear: behavior_report_detail.B22170027 || "-"
      // },
      // {
      //   name: "M1+逾期贷款笔数",
      //   oneMonth: "-",
      //   threeMonth: "-",
      //   sixMonth: behavior_report_detail.B22170028 || "-",
      //   oneYear: behavior_report_detail.B22170029 || "-",
      //   twoYear: behavior_report_detail.B22170030 || "-"
      // },
      // {
      //   name: "累计逾期金额",
      //   oneMonth: "-",
      //   threeMonth: "-",
      //   sixMonth: behavior_report_detail.B22170031 || "-",
      //   oneYear: behavior_report_detail.B22170032 || "-",
      //   twoYear: behavior_report_detail.B22170033 || "-"
      // },
      {
        name: "还款成功总金额",
        oneMonth: behavior_report_detail.B22170040 || "-",
        threeMonth: behavior_report_detail.B22170041 || "-",
        sixMonth: behavior_report_detail.B22170042 || "-",
        oneYear: behavior_report_detail.B22170043 || "-",
        twoYear: behavior_report_detail.B22170044 || "-"
      },
      {
        name: "成功扣款笔数",
        oneMonth: behavior_report_detail.B22170045 || "-",
        threeMonth: behavior_report_detail.B22170046 || "-",
        sixMonth: behavior_report_detail.B22170047 || "-",
        oneYear: behavior_report_detail.B22170048 || "-",
        twoYear: behavior_report_detail.B22170049 || "-"
      },
      {
        name: "失败扣款笔数",
        oneMonth: behavior_report_detail.B22170035 || "-",
        threeMonth: behavior_report_detail.B22170036 || "-",
        sixMonth: behavior_report_detail.B22170037 || "-",
        oneYear: behavior_report_detail.B22170038 || "-",
        twoYear: behavior_report_detail.B22170039 || "-"
      }
    ];
  },
  creditList: state => {
    let { current_report_detail = {} } = state;
    return [
      {
        name: "网贷授信额度",
        num: current_report_detail.C22180001 || "-",
      },
      {
        name: "网贷额度置信度",
        num: current_report_detail.C22180002 || "-",
      },
      {
        name: "网络贷款类机构数",
        num: current_report_detail.C22180003 || "-",
      },
      {
        name: "网络贷款类产品数",
        num: current_report_detail.C22180004 || "-",
      },
      {
        name: "网络贷款机构最大授信额度",
        num: current_report_detail.C22180005 || "-",
      },
      {
        name: "网络贷款机构平均授信额度",
        num: current_report_detail.C22180006 || "-",
      },
      {
        name: "消金建议授信额度",
        num: current_report_detail.C22180011 || "-",
      },
      {
        name: "消金额度置信度",
        num: current_report_detail.C22180012 || "-",
      },
      {
        name: "消金贷款类机构数",
        num: current_report_detail.C22180007 || "-",
      },
      {
        name: "消金贷款类产品数",
        num: current_report_detail.C22180008 || "-",
      },
      {
        name: "消金贷款类机构最大授信额度",
        num: current_report_detail.C22180009 || "-",
      },
      {
        name: "消金贷款类机构平均授信额度",
        num: current_report_detail.C221800010 || "-",
      }
    ]
  },
};
