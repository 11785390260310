import {create as createAxios} from 'axios';

const axios = createAxios();

var baseUrlSet = process.env.VUE_APP_BASE_URL.split(',').map(u => ({url: u, primary: false}));
baseUrlSet[0].primary = true;

const rankBaseUrl = () => {
  Promise.race(baseUrlSet.filter(u => !u.primary).map(u => {
    const pingAt = new Date();
    return axios.get(`${u.url}ping`)
      .then(res => {
        // console.log((new Date() - pingAt) + `ms ${u.url}`)
        if (res && res.status === 200 && res.data && typeof res.data == 'string' && res.data.toUpperCase() === 'PONG')
          return u;
        throw new Error()
      })
  })).then(fasterUrl => {
    setBaseUrl(fasterUrl.url);
  }).catch(() => {
    setBaseUrlUsingPrimary();
  })
}

/**
 * baseUrl
 */
var baseUrl, base;

const setBaseUrl = url => {
  base = url;
  baseUrl = base + "api/v2/";
}

const setBaseUrlUsingPrimary = () => setBaseUrl(baseUrlSet.filter(u => u.primary).pop().url);

/**
 * Init
 */
setBaseUrlUsingPrimary();
setTimeout(() => {
  rankBaseUrl();
  setInterval(() => rankBaseUrl(), 30000);
}, 0);

export {
  baseUrl,
  base,
};
