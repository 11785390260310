import service from "../../api";
import stateData from "./state";
import getters from "./getters";
import format from "./format"

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {


    setTcReport(state, data = {}) {
      for (let key in data) {
        // let e = format[key];
        // // console.log(key, format[key],data[key])
        // // console.log(e["m1"])
        // for (let keys in e){
        //   // console.log(keys)
        // }
        if(format[key] != undefined){

          let result = format[key];

          for (const resultKey in result) {
            if(resultKey+"" === data[key] +""){
              state[key] = result[resultKey]
              break
            }
          }
          // console.log(key,state[key])

        }else{
          state[key] = data[key];
          // console.log(key,state[key])
        }


      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    // 全景雷达信息
    getTcReport({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("setTcReport", {
        ...stateData
      });
      commit("userReport/setLoading", true, {
        root: true
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'TCREPORT')
        .then(res => {
          // console.log(res.data['TCREPORT'].data )
          commit("setTcReport", res.data['TCREPORT'].data || {});
          commit("setProvider", res.provider || "");
          commit("setRefreshDate", res.date),
            commit("userReport/setLoading", false, {
              root: true
            });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters
};
