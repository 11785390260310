export default {
  reportURL:"",
  provider: "",
  currently_overdue: "",
  max_overdue_amt: "",
  acc_sleep: "",
  currently_performance: "",
  result_code: "",
  max_overdue_days:"",
  latest_overdue_time: "",
  acc_exc: "",
  date: ""
};
