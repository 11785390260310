import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setTza(state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    getTza({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setTza", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'TZA')
        .then(res => {
          // console.log(JSON.stringify(res.data))
          if(res.data == null){
            commit("userReport/setLoading", false, {
              root: true
            });
            return true;
          }
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setTza", res.data["TZA"].data);
          commit("setProvider", res.provider || "");
          commit("setRefreshDate", res.date),
          commit("userReport/setLoading", false, {
            root: true
          });
          return res;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    data: state => {
      let data = state.result_detail['LIUSHUI'].data.list
      return data
    }
  }
};
