import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {...stateData},
  mutations: {
    setOverdueRisk(state, data = {}) {
      for (let key in data) {
        state[key] = data[key];
      }
    }
  },
  actions: {
    // 获取逾期风险数据
    getOverdueRisk({ rootState, commit }, refresh) {
      let { uid = "" } = rootState.userReport;
     commit("userReport/setLoading", true, { root: true });
      // 重置state
      commit("setOverdueRisk", { ...stateData });
      return service
        .getOverdueRisk({ uid , refresh})
        .then(res => {
          commit("setOverdueRisk", res);
         commit("userReport/setLoading", false, { root: true });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, { root: true });
          return false;
        });
    }
  },
  getters:{
    overdueList: state => {
      let { records } = state;
      let list = Object.values(records);
      return list;
    }
  }
}
