import service from "../../api";
import stateData from "./state";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setHead(state, data = {}) {
      if(data.code == 200){
        data =  data.data
      }
      for (let key in data) {
        key = String(key)
        if(data[key] == '0'){
          state[key] = 0
        }else


        if(key == 'ovdure_org_num'){
          if (data[key] == '1') state[key] = '[1,2)';
          if (data[key] == '2') state[key] = '[2,4)';
          if (data[key] == '3') state[key] = '[4,Inf)';
        }else
        /**
         * [11000,Inf)

         */
        if (key == 'ovdure_org_money') {
          if (data[key] == '1') state[key] = '[0,1000)';
          if (data[key] == '2') state[key] = '[1000,2000)';
          if (data[key] == '3') state[key] = '[2000,3000)';
          if (data[key] == '4') state[key] = '[3000,5000)';
          if (data[key] == '5') state[key] = '[5000,7000)' ; // console.log(data[key],key);
          if (data[key] == '6') state[key] = '[7000,11000)';
          if (data[key] == '7') state[key] = '[11000,Inf)';
        }else
        if(key == 'debt_first_distance_now'){
          if (data[key] == '1') state[key] = '(0,220)';
          if (data[key] == '2') state[key] = '[220,450)';
          if (data[key] == '3') state[key] = '[450,520)';
          if (data[key] == '4') state[key] = '[520,Inf)';

        }else
        if (key == 'debt_org_total_num'){
          if (data[key] == '1') state[key] = '(0,9)';
          if (data[key] == '2') state[key] = '[9,14)';
          if (data[key] == '3') state[key] = '[14,Inf)';
        }else
        if (key == 'debt_shopping_total_num'){
          if (data[key] == '1') state[key] = '(0,1)';
          if (data[key] == '2') state[key] = '[1,2)';
          if (data[key] == '3') state[key] = '[2,4)';
          if (data[key] == '4') state[key] = '[4,Inf)';

        }else

        if (key == 'debt_www_total_num'){
          if (data[key] == '1') state[key] = '(0,1)';
          if (data[key] == '2') state[key] = '[1,5)';
          if (data[key] == '3') state[key] = '[5,11)';
          if (data[key] == '4') state[key] = '[11,Inf)';
        }else
        if (key == 'debt_settled_num'){
          if (data[key] == '1') state[key] = '(0,5)';
          if (data[key] == '2') state[key] = '[5,9)';
          if (data[key] == '3') state[key] = '[9,14)';
          if (data[key] == '4') state[key] = '[14,17)';
          if (data[key] == '5') state[key] = '[17,Inf)';
        }else
        if (key == 'debt_org_d7_num'){
          if (data[key] == '1') state[key] = '(0,1)';
          if (data[key] == '2') state[key] = '[1,9)';
          if (data[key] == '3') state[key] = '[9,Inf)';
        }else
        if (key == 'debt_org_m1_num'){
          if (data[key] == '1') state[key] = '(0,1)';
          if (data[key] == '2') state[key] = '[1,4)';
          if (data[key] == '3') state[key] = '[4,11)';
          if (data[key] == '4') state[key] = '[11,Inf)';
        }
        if (key == 'debt_org_m3_num'){
          if (data[key] == '1') state[key] = '(0,5)';
          if (data[key] == '2') state[key] = '[5,10)';
          if (data[key] == '3') state[key] = '[10,17)';
          if (data[key] == '4') state[key] = '[17,Inf)';
          // console.log(data[key],state[key])
        }else
        if (key == 'debt_org_m6_num'){
          if (data[key] == '1') state[key] = '(0,4)';
          if (data[key] == '2') state[key] = '[4,7)';
          if (data[key] == '3') state[key] = '[7,13)';
          if (data[key] == '4') state[key] = '[13,Inf)';
        }else
        if (key == 'repay_fail_d7_num'){
          if (data[key] == '1') state[key] = '(0,3)';
          if (data[key] == '2') state[key] = '[3,5)';
          if (data[key] == '3') state[key] = '[5,7)';
          if (data[key] == '4') state[key] = '[7,Inf)';
        }else
        if (key == 'repay_fail_m1_num'){
          if (data[key] == '1') state[key] = '(0,3)';
          if (data[key] == '2') state[key] = '[3,5)';
          if (data[key] == '3') state[key] = '[5,34)';
          if (data[key] == '4') state[key] = '[34,Inf)';
        }else
        if (key == 'repay_fail_m3_num'){
          if (data[key] == '1') state[key] = '(0,6)';
          if (data[key] == '2') state[key] = '[6,22)';
          if (data[key] == '3') state[key] = '[22,56)';
          if (data[key] == '4') state[key] = '[56,Inf)';
        }else
        if (key == 'repay_fail_m6_num'){
          if (data[key] == '1') state[key] = '(0,3)';
          if (data[key] == '2') state[key] = '[3,25)';
          if (data[key] == '3') state[key] = '[20,30)';
          if (data[key] == '4') state[key] = '[30,70)';
          if (data[key] == '5') state[key] = '[70,Inf)';
        }else
        if (key == 'repay_fail_d7_money'){
          if (data[key] == '1') state[key] = '(0,2000)';
          if (data[key] == '2') state[key] = '[2000,10000)';
          if (data[key] == '3') state[key] = '[10000,17000)';
          if (data[key] == '4') state[key] = '[17000,Inf)';
        }else
        if (key == 'repay_fail_m1_money'){
          if (data[key] == '1') state[key] = '(0,6000)';
          if (data[key] == '2') state[key] = '[6000,10000)';
          if (data[key] == '3') state[key] = '[10000,36000)';
          if (data[key] == '4') state[key] = '[36000,Inf)';
       }else
        if (key == 'repay_fail_m3_money'){
          if (data[key] == '1') state[key] = '(0,10000)';
          if (data[key] == '2') state[key] = '[10000,50000)';
          if (data[key] == '3') state[key] = '[50000,80000)';
          if (data[key] == '4') state[key] = '[80000,Inf)';
       }else
        if (key == 'repay_fail_m6_money'){
          if (data[key] == '1') state[key] = '(0,2000)';
          if (data[key] == '2') state[key] = '[2000,30000)';
          if (data[key] == '3') state[key] = '[30000,60000)';
          if (data[key] == '4') state[key] = '[60000,90000)';
          if (data[key] == '5') state[key] = '[90000,Inf)';
        }else

        if (key == 'repay_succ_d7_num'){
          if (data[key] == '1') state[key] = '(0,3)';
          if (data[key] == '2') state[key] = '(0,3)';
          if (data[key] == '3') state[key] = '[13,Inf)';
        }else
        if (key == 'repay_succ_m1_num'){
          if (data[key] == '1') state[key] = '(0,7)';
          if (data[key] == '2') state[key] = '[7,34)';
          if (data[key] == '3') state[key] = '[34,Inf)';
        }else
        if (key == 'repay_succ_m3_num'){
          if (data[key] == '1') state[key] = '(0,2)';
          if (data[key] == '2') state[key] = '[2,31)';
          if (data[key] == '3') state[key] = '[31,Inf)';
        }else
        if (key == 'repay_succ_m6_num'){
          if (data[key] == '1') state[key] = '(0,3)';
          if (data[key] == '2') state[key] = '[3,27)';
          if (data[key] == '3') state[key] = '[27,50)';
          if (data[key] == '4') state[key] = '[50,Inf)';
        }else
        if (key == 'repay_succ_d7_money'){
          if (data[key] == '1') state[key] = '(0,2000)';
          if (data[key] == '2') state[key] = '[2000,17000)';
          if (data[key] == '3') state[key] = '[17000,Inf)';
        }else
        if (key == 'repay_succ_m1_money'){
          if (data[key] == '1') state[key] = '(0,10000)';
          if (data[key] == '2') state[key] = '[10000,36000)';
          if (data[key] == '3') state[key] = '[36000,Inf)';
        }else
        if (key == 'repay_succ_m3_money'){
          if (data[key] == '1') state[key] = '(0,13000)';
          if (data[key] == '2') state[key] = '[13000,49000)';
          if (data[key] == '3') state[key] = '[49000,Inf)';
        }else
        if (key == 'repay_succ_m6_money'){
          if (data[key] == '1') state[key] = '(0,2000)';
          if (data[key] == '2') state[key] = '[2000,58000)';
          if (data[key] == '3') state[key] = '58000,Inf)';
        }
        if(key == 'ovdure_flag') state[key] = data[key]
        if(key = 'apply_org_by_id_d7_num') state[key] = data[key]
        if(key = 'apply_org_by_id_m1_num') state[key] = data[key]
        if(key = 'apply_org_by_id_m3_num') state[key] = data[key]
        if(key = 'apply_org_by_id_m6_num') state[key] = data[key]
        if(key = 'apply_org_by_phone_d7_num') state[key] = data[key]
        if(key = 'apply_org_by_phone_m1_num') state[key] = data[key]
        if(key = 'apply_org_by_phone_m3_num') state[key] = data[key]
        if(key = 'apply_org_by_phone_m6_num') state[key] = data[key]
        if(key = 'apply_by_id_d7_num') state[key] = data[key]
        if(key = 'apply_by_id_m1_num') state[key] = data[key]
        if(key = 'apply_by_id_m3_num') state[key] = data[key]
        if(key = 'apply_by_id_m6_num') state[key] = data[key]
        if(key = 'apply_by_phone_d7_num') state[key] = data[key]
        if(key = 'apply_by_phone_m1_num') state[key] = data[key]
        if(key = 'apply_by_phone_m3_num') state[key] = data[key]
        if(key = 'apply_by_phone_m6_num') state[key] = data[key]



      }
      state['buildUUData'] = [
        {
          n1: '平台数',
          n2: '身份证',
          m1: state['apply_org_by_id_d7_num'],
          m2: state['apply_org_by_id_m1_num'],
          m3: state['apply_org_by_id_m3_num'],
          m4: state['apply_org_by_id_m6_num']
        },
        {
          n1: '平台数',
          n2: '手机号',
          m1: state['apply_org_by_phone_d7_num'],
          m2: state['apply_org_by_phone_m1_num'],
          m3: state['apply_org_by_phone_m3_num'],
          m4: state['apply_org_by_phone_m6_num']
        },
        {
          n1: '次数',
          n2: '身份证',
          m1: state['apply_by_id_d7_num'],
          m2: state['apply_by_id_m1_num'],
          m3: state['apply_by_id_m3_num'],
          m4: state['apply_by_id_m6_num']
        },
        {
          n1: '次数',
          n2: '手机号',
          m1: state['apply_by_phone_d7_num'],
          m2: state['apply_by_phone_m1_num'],
          m3: state['apply_by_phone_m3_num'],
          m4: state['apply_by_phone_m6_num']
        },
      ]
      state['buildData'] = [
        {
          name: '还款失败次数',
          m1: state['repay_fail_d7_num'],
          m2: state['repay_fail_m1_num'],
          m3: state['repay_fail_m3_num'],
          m4: state['repay_fail_m6_num']
        },
        {
          name: '还款失败金额',
          m1: state['repay_fail_d7_money'],
          m2: state['repay_fail_m1_money'],
          m3: state['repay_fail_m3_money'],
          m4: state['repay_fail_m6_money']
        },
        {
          name: '还款成功次数',
          m1: state['repay_succ_d7_num'],
          m2: state['repay_succ_m1_num'],
          m3: state['repay_succ_m3_num'],
          m4: state['repay_succ_m6_num']
        },
        {
          name: '还款成功金额',
          m1: state['repay_succ_d7_money'],
          m2: state['repay_succ_m1_money'],
          m3: state['repay_succ_m3_money'],
          m4: state['repay_succ_m6_money']
        }

      ]
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    // 全景雷达信息
    getHead({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("setHead", {
        ...stateData
      });
      commit("userReport/setLoading", true, {
        root: true
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'HEAD')
        .then(res => {
          commit("setHead", res.data['HEAD'] || {});
          commit("setProvider", res.provider || "");
          commit("setRefreshDate", res.date),
            commit("userReport/setLoading", false, {
              root: true
            });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  }
};
