export default {
  provider: "",
  "base_info":null,
  "court_risk_info_list": null,
  "hit_risk_tagging":null,
  "ident_info_dict":null,
  "personal_loan_demand":null,
  "personal_loan_f":null,
  "personal_loan_s":null,
  "personal_loan_h":null,
  "personal_overdue_history":null,
  "relevance_risk_check":null,
  "risk_list_check":null,
  "rent_history":null,
  "verify_recomment":null,
  "score_norm_explain":null,
  "suggest_cash":null,
  date: ""
};
