export default  {
  "xyp_cpl0001": {"1": "(0,9)", "2": "[9,14)", "3": "[14, Inf)"},
  "xyp_cpl0002": {"1": "(0,5)", "2": "[5,9)", "3": "[9,14)", "4": "[14,17)", "5": "[17,Inf)"},
  "xyp_cpl0044": {"1": "逾期", 0: "未逾期"},
  "xyp_cpl0007": {"1": "(0,1)", "2": "[1,2)", "3": "[2,4)", "4": "[4, Inf)"},
  "xyp_cpl0008": {"1": "(0,1)", "2": "[1,5)", "3": "[5,11)", "4": "[11, Inf)"},
  "xyp_cpl0070": {"1": "(0,1)", "2": "[1,3)", "3": "[3, Inf)"},
  "xyp_cpl0009": {"1": "(0,1)", "2": "[1,9)", "3": "[9, Inf)"},
  "xyp_cpl0010": {"1": "(0,1)", "2": "[1,3)", "3": "[3,10)", "4": "[10, Inf)"},
  "xyp_cpl0063": {"1": "(0,3)", "2": "[3,6)", "3": "[6,11)", "4": "[11, Inf)"},
  "xyp_cpl0011": {"1": "(0,1)", "2": "[1,4)", "3": "[4,11)", "4": "[11, Inf)"},
  "xyp_cpl0012": {"1": "(0,5)", "2": "[5,10)", "3": "[10,17)", "4": "[17, Inf)"},
  "xyp_cpl0013": {"1": "(0,4)", "2": "[4,7)", "3": "[7,13)", "4": "[13, Inf)"},
  "xyp_cpl0014": {"1": "(0,2)", "2": "[2,10)", "3": "[10,34)", "4": "[34,60)", "5": "[60, Inf)"},
  "xyp_cpl0015": {"1": "(0,3)", "2": "[3,5)", "3": "[5,10)", "4": "[10,Inf)"},
  "xyp_cpl0016": {"1": "(0,3)", "2": "[3,5)", "3": "[5,Inf)"},
  "xyp_cpl0017": {"1": "(0,3)", "2": "[3, Inf)"},
  "xyp_cpl0018": {"1": "(0,3)", "2": "[3,5)", "3": "[5,7)", "4": "[7,Inf)"},
  "xyp_cpl0019": {"1": "(0,3)", "2": "[3,13)", "3": "[13, Inf)"},
  "xyp_cpl0020": {"1": "(0,3)", "2": "[3,5)", "3": "[5,15)", "4": "[15,Inf)"},
  "xyp_cpl0021": {"1": "(0,5)", "2": "[5,21)", "3": "[21, Inf)"},
  "xyp_cpl0064": {"1": "(0,5)", "2": "[5,27)", "3": "[27, Inf)"},
  "xyp_cpl0065": {"1": "(0,3)", "2": "[3,5)", "3": "[5,20)", "4": "[20,Inf)"},
  "xyp_cpl0022": {"1": "(0,3)", "2": "[3,5)", "3": "[5,34)", "4": "[34,Inf)"},
  "xyp_cpl0023": {"1": "(0,7)", "2": "[7,34)", "3": "[34, Inf)"},
  "xyp_cpl0024": {"1": "(0,6)", "2": "[6,22)", "3": "[22,56)", "4": "[56,Inf)"},
  "xyp_cpl0025": {"1": "(0,2)", "2": "[2,31)", "3": "[31, Inf)"},
  "xyp_cpl0026": {"1": "(0,3)", "2": "[3,25)", "3": "[25,30)", "4": "[30,70)", "5": "[70,Inf)"},
  "xyp_cpl0027": {"1": "(0,3)", "2": "[3,27)", "3": "[27,50)", "4": "[50, Inf)"},
  "xyp_cpl0028": undefined,
  "xyp_cpl0029": undefined,
  "xyp_cpl0030": undefined,
  "xyp_cpl0031": undefined,
  "xyp_cpl0032": {"1": "(0,1500)", "2": "[1500,2500)", "3": "[2500,5000)", "4": "[5000,Inf)"},
  "xyp_cpl0033": {"1": "(0,1500)", "2": "[1500,5000)", "3": "[5000, Inf)"},
  "xyp_cpl0034": {"1": "(0,2000)", "2": "[2000,10000)", "3": "[10000,17000)", "4": "[17000,26000)", "5": "[26000,Inf)"},
  "xyp_cpl0035": {"1": "(0,2000)", "2": "[2000,17000)", "3": "[17000, Inf)"},
  "xyp_cpl0036": {"1": "(0,1000)", "2": "[1000,4000)", "3": "[4000,9000)", "4": "[9000,30000)", "5": "[30000,Inf)"},
  "xyp_cpl0037": {"1": "(0,9000)", "2": "[9000,31000)", "3": "[31000, Inf)"},
  "xyp_cpl0066": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000,30000)", "4": "[30000,50000)", "5": "[50000,Inf)"},
  "xyp_cpl0067": {"1": "(0,1000)", "2": "[1000,34000)", "3": "[34000, Inf)"},
  "xyp_cpl0038": {"1": "(0,6000)", "2": "[6000,10000)", "3": "[10000,50000)", "4": "[50000,Inf)"},
  "xyp_cpl0039": {"1": "(0,10000)", "2": "[10000,36000)", "3": "[36000, Inf)"},
  "xyp_cpl0040": {"1": "(0,10000)", "2": "[10000,50000)", "3": "[50000,80000)", "4": "[80000,Inf)"},
  "xyp_cpl0041": {"1": "(0,13000)", "2": "[13000,49000)", "3": "[49000, Inf)"},
  "xyp_cpl0042": {"1": "(0,2000)", "2": "[2000,30000)", "3": "[30000,60000)", "4": "[60000,90000)", "5": "[90000,Inf)"},
  "xyp_cpl0043": {"1": "(0,2000)", "2": "[2000,58000)", "3": "[58000, Inf)"},
  "xyp_cpl0045": {"1": "(0, 220)", "2": "[220,450)", "3": "[450,520)", "4": "[520, Inf)"},
  "xyp_cpl0046": {"1": "(0,5)", "2": "[5,25)", "3": "[25, Inf)"},
  "xyp_cpl0048": {"1": "(0,1)", "2": "[1,2)", "3": "[2, Inf)"},
  "xyp_cpl0049": {"1": "(0,1)", "2": "[1,3)", "3": "[3, Inf)"},
  "xyp_cpl0050": {"1": "(0,1)", "2": "[1,3)", "3": "[3, Inf)"},
  "xyp_cpl0051": {"1": "(0,1)", "2": "[1,3)", "3": "[3, Inf)"},
  "xyp_cpl0068": {"1": "(0,5)", "2": "[5,50)", "3": "[50,160)", "4": "[160, Inf)"},
  "xyp_cpl0071": {"1": "[1,2)", "2": "[2,4)", "3": "[4, Inf)"},
  "xyp_cpl0072": {
    "1": "(0,1000)",
    "2": "[1000,2000)",
    "3": "[2000,3000)",
    "4": "[3000,5000)",
    "5": "[5000,7000)",
    "6": "[7000,11000)",
    "7": "[11000,Inf)"
  },
  "xyp_cpl0073": undefined,
  "xyp_cpl0074": undefined,
  "xyp_cpl0075": undefined,
  "xyp_cpl0076": {"1": "(0,2)", "2": "[2,11)", "3": "[11,13)", "4": "[13, Inf)"},
  "xyp_cpl0077": {"1": "(0,4000)", "2": "[4000,8000)", "3": "[8000, Inf)"},
  "xyp_cpl0078": {"1": "(0,2000)", "2": "[2000,25000)", "3": "[25000, Inf)"},
  "xyp_cpl0079": undefined,
  "xyp_cpl0080": undefined,
  "xyp_t01aabzbz": {"1": "(0,1000)", "2": "[1000,1600)", "3": "[1600, Inf)"},
  "xyp_t01aadzbz": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aaezbc": {"1": "(0,1000)", "2": "[1000,1400)", "3": "[1400, Inf)"},
  "xyp_t01aafzbc": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aafzbz": {"1": "(0,1000)", "2": "[1000,4200)", "3": "[4200, Inf)"},
  "xyp_t01aafzzc": {"1": "(0, 600)", "2": "[600,1000)", "3": "[1000, Inf)"},
  "xyp_t01aafzzz": {"1": "(0, 400)", "2": "[400,1000)", "3": "[1000, Inf)"},
  "xyp_t01aagzaz": {"1": "(0,1000)", "2": "[1000,2300)", "3": "[2300, Inf)"},
  "xyp_t01aagzbz": {"1": "(0,1000)", "2": "[1000,4600)", "3": "[4600, Inf)"},
  "xyp_t01aagzzc": {"1": "(0,1000)", "2": "[1000,4800)", "3": "[4800, Inf)"},
  "xyp_t01aahzbz": {"1": "(0,1000)", "2": "[1000,4800)", "3": "[4800, Inf)"},
  "xyp_t01aahzza": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aaizba": {"1": "(0,1000)", "2": "[1000,1800)", "3": "[1800, Inf)"},
  "xyp_t01aaizbz": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aaizzc": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aaizzz": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aajzba": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aajzza": {"1": "(0,1000)", "2": "[1000,1400)", "3": "[1400, Inf)"},
  "xyp_t01aajzzc": {"1": "(0,2000)", "2": "[2000,4200)", "3": "[4200, Inf)"},
  "xyp_t01aakzba": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aakzzz": {"1": "(0,1000)", "2": "[1000,4600)", "3": "[4600, Inf)"},
  "xyp_t01aalzbc": {"1": "(0,1000)", "2": "[1000,4600)", "3": "[4600, Inf)"},
  "xyp_t01aalzbz": {"1": "(0,1000)", "2": "[1000,4600)", "3": "[4600, Inf)"},
  "xyp_t01aalzzc": {"1": "(0,1000)", "2": "[1000,4600)", "3": "[4600, Inf)"},
  "xyp_t01aalzzz": {"1": "(0,1000)", "2": "[1000,4600)", "3": "[4600, Inf)"},
  "xyp_t01aazzzc": {"1": "(0,1000)", "2": "[1000,4800)", "3": "[4800, Inf)"},
  "xyp_t01abdzbz": {"1": "(0, 500)", "2": "[500,1300)", "3": "[1300, Inf)"},
  "xyp_t01abezbz": {"1": "(0, 500)", "2": "[500,1300)", "3": "[1300, Inf)"},
  "xyp_t01abfzba": {"1": "(0, 300)", "2": "[300,1300)", "3": "[1300, Inf)"},
  "xyp_t01abfzbz": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abfzza": {"1": "(0, 600)", "2": "[600,1300)", "3": "[1300, Inf)"},
  "xyp_t01abfzzc": {"1": "(0,50)", "2": "[50,450)", "3": "[450, Inf)"},
  "xyp_t01abfzzz": {"1": "(0,50)", "2": "[50,450)", "3": "[450, Inf)"},
  "xyp_t01abgzbc": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abgzzc": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abhzbc": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abizbz": {"1": "(0, 200)", "2": "[200,500)", "3": "[500, Inf)"},
  "xyp_t01abizzc": {"1": "(0, 200)", "2": "[200,650)", "3": "[650,1950)", "4": "[1950, Inf)"},
  "xyp_t01abjzbc": {"1": "(0,50)", "2": "[50,200)", "3": "[200,500)", "4": "[500, Inf)"},
  "xyp_t01abjzzc": {"1": "(0,50)", "2": "[50,100)", "3": "[100,200)", "4": "[200, Inf)"},
  "xyp_t01abkzba": {"1": "(0,1000)", "2": "[1000,1600)", "3": "[1600, Inf)"},
  "xyp_t01abkzbc": {"1": "(0,50)", "2": "[50,100)", "3": "[100,450)", "4": "[450, Inf)"},
  "xyp_t01abkzbz": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abkzza": {"1": "(0, 600)", "2": "[600,1700)", "3": "[1700, Inf)"},
  "xyp_t01ablzbc": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abzzza": {"1": "(0, 600)", "2": "[600,1300)", "3": "[1300, Inf)"},
  "xyp_t01acbzzz": {"1": "(0,11000)", "2": "[11000,26000)", "3": "[26000, Inf)"},
  "xyp_t01acczzz": {"1": "(0,1000)", "2": "[1000,36000)", "3": "[36000, Inf)"},
  "xyp_t01acdzbz": {"1": "(0,1000)", "2": "[1000,34000)", "3": "[34000, Inf)"},
  "xyp_t01acdzzz": {"1": "(0,2000)", "2": "[2000,52000)", "3": "[52000, Inf)"},
  "xyp_t01acezba": {"1": "(0,3000)", "2": "[3000,8000)", "3": "[8000, Inf)"},
  "xyp_t01acezbz": {"1": "(0,6000)", "2": "[6000,54000)", "3": "[54000, Inf)"},
  "xyp_t01acezzz": {"1": "(0,6000)", "2": "[6000,52000)", "3": "[52000, Inf)"},
  "xyp_t01acfzbc": {"1": "(0,12000)", "2": "[12000,40000)", "3": "[40000, Inf)"},
  "xyp_t01acfzbz": {"1": "(0,10000)", "2": "[10000,42000)", "3": "[42000, Inf)"},
  "xyp_t01acfzza": {"1": "(0,3000)", "2": "[3000,18000)", "3": "[18000, Inf)"},
  "xyp_t01acgzbc": {"1": "(0,2000)", "2": "[2000,46000)", "3": "[46000, Inf)"},
  "xyp_t01acgzza": {"1": "(0,1000)", "2": "[1000,18000)", "3": "[18000, Inf)"},
  "xyp_t01acgzzz": {"1": "(0,26000)", "2": "[26000,92000)", "3": "[92000, Inf)"},
  "xyp_t01achzaz": {"1": "(0,1000)", "2": "[1000,3000)", "3": "[3000, Inf)"},
  "xyp_t01achzbc": {"1": "(0,2000)", "2": "[2000,42000)", "3": "[42000, Inf)"},
  "xyp_t01achzbz": {"1": "(0,6000)", "2": "[6000,30000)", "3": "[30000, Inf)"},
  "xyp_t01achzzc": {"1": "(0,4000)", "2": "[4000,64000)", "3": "[64000, Inf)"},
  "xyp_t01acizba": {"1": "(0,1000)", "2": "[1000,3200)", "3": "[3200, Inf)"},
  "xyp_t01acizbc": {"1": "(0,3000)", "2": "[3000,5500)", "3": "[5500, Inf)"},
  "xyp_t01acizbz": {"1": "(0,2000)", "2": "[2000,7500)", "3": "[7500, Inf)"},
  "xyp_t01acizza": {"1": "(0,1000)", "2": "[1000,3200)", "3": "[3200, Inf)"},
  "xyp_t01acizzz": {"1": "(0,3000)", "2": "[3000,8000)", "3": "[8000, Inf)"},
  "xyp_t01acjzaz": {"1": "(0,1500)", "2": "[1500,3000)", "3": "[3000, Inf)"},
  "xyp_t01acjzba": {"1": "(0,3000)", "2": "[3000,9000)", "3": "[9000, Inf)"},
  "xyp_t01acjzbc": {"1": "(0,13000)", "2": "[13000,19000)", "3": "[19000, Inf)"},
  "xyp_t01acjzbz": {"1": "(0,7000)", "2": "[7000,26000)", "3": "[26000, Inf)"},
  "xyp_t01acjzzz": {"1": "(0,10000)", "2": "[10000,34000)", "3": "[34000, Inf)"},
  "xyp_t01ackzaz": {"1": "(0,1000)", "2": "[1000,3000)", "3": "[3000, Inf)"},
  "xyp_t01ackzbz": {"1": "(0,24000)", "2": "[24000,64000)", "3": "[64000, Inf)"},
  "xyp_t01ackzza": {"1": "(0,3000)", "2": "[3000,19000)", "3": "[19000, Inf)"},
  "xyp_t01ackzzc": {"1": "(0,2000)", "2": "[2000,14000)", "3": "[14000, Inf)"},
  "xyp_t01ackzzz": {"1": "(0,30000)", "2": "[30000,70000)", "3": "[70000, Inf)"},
  "xyp_t01aclzbc": {"1": "(0,2000)", "2": "[2000,42000)", "3": "[42000, Inf)"},
  "xyp_t01aclzbz": {"1": "(0,36000)", "2": "[36000,104000)", "3": "[104000, Inf)"},
  "xyp_t01aclzzc": {"1": "(0,14000)", "2": "[14000,64000)", "3": "[64000, Inf)"},
  "xyp_t01aclzzz": {"1": "(0,10000)", "2": "[10000,45000)", "3": "[45000, Inf)"},
  "xyp_t01aczzaz": {"1": "(0,3000)", "2": "[3000,27000)", "3": "[27000, Inf)"},
  "xyp_t01aczzbc": {"1": "(0,12000)", "2": "[12000,54000)", "3": "[54000, Inf)"},
  "xyp_t01aczzzc": {"1": "(0,14000)", "2": "[14000,68000)", "3": "[68000, Inf)"},
  "xyp_t01aczzzz": {"1": "(0,45000)", "2": "[45000,115000)", "3": "[115000, Inf)"},
  "xyp_t01adczbz": {"1": "(0, 600)", "2": "[600,1600)", "3": "[1600, Inf)"},
  "xyp_t01addzbz": {"1": "(0, 600)", "2": "[600,1700)", "3": "[1700, Inf)"},
  "xyp_t01adfzbz": {"1": "(0, 600)", "2": "[600,1400)", "3": "[1400, Inf)"},
  "xyp_t01adfzza": {"1": "(0, 500)", "2": "[500,1700)", "3": "[1700, Inf)"},
  "xyp_t01adfzzc": {"1": "(0, 600)", "2": "[600,1800)", "3": "[1800, Inf)"},
  "xyp_t01adfzzz": {"1": "(0, 700)", "2": "[700,1800)", "3": "[1800, Inf)"},
  "xyp_t01adgzba": {"1": "(0, 300)", "2": "[300,2000)", "3": "[2000, Inf)"},
  "xyp_t01adgzbc": {"1": "(0, 600)", "2": "[600,1600)", "3": "[1600, Inf)"},
  "xyp_t01adgzza": {"1": "(0, 400)", "2": "[400,1700)", "3": "[1700, Inf)"},
  "xyp_t01adgzzc": {"1": "(0, 600)", "2": "[600,1600)", "3": "[1600, Inf)"},
  "xyp_t01adhzbc": {"1": "(0, 600)", "2": "[600,1600)", "3": "[1600, Inf)"},
  "xyp_t01adizbc": {"1": "(0, 300)", "2": "[300,1600)", "3": "[1600, Inf)"},
  "xyp_t01adizbz": {"1": "(0, 700)", "2": "[700,1500)", "3": "[1500, Inf)"},
  "xyp_t01adizzc": {"1": "(0,1600)", "2": "[1600,2000)", "3": "[2000, Inf)"},
  "xyp_t01adizzz": {"1": "(0, 700)", "2": "[700,1600)", "3": "[1600, Inf)"},
  "xyp_t01adjzba": {"1": "(0, 300)", "2": "[300,1300)", "3": "[1300, Inf)"},
  "xyp_t01adjzbc": {"1": "(0,1300)", "2": "[1300,1700)", "3": "[1700, Inf)"},
  "xyp_t01adjzzc": {"1": "(0,1300)", "2": "[1300,1700)", "3": "[1700, Inf)"},
  "xyp_t01adjzzz": {"1": "(0, 700)", "2": "[700,1700)", "3": "[1700, Inf)"},
  "xyp_t01adkzbc": {"1": "(0,1300)", "2": "[1300,1700)", "3": "[1700, Inf)"},
  "xyp_t01adkzzc": {"1": "(0, 600)", "2": "[600,1700)", "3": "[1700, Inf)"},
  "xyp_t01adlzzc": {"1": "(0, 600)", "2": "[600,1700)", "3": "[1700, Inf)"},
  "xyp_t01adzzba": {"1": "(0, 300)", "2": "[300,1600)", "3": "[1600, Inf)"},
  "xyp_t01bazzba": {"1": "(0,50)", "2": "[50,90)", "3": "[90,180)", "4": "[180,420)", "5": "[420, Inf)"},
  "xyp_t01bazzbc": {"1": "(0, 220)", "2": "[220,350)", "3": "[350,440)", "4": "[440,500)", "5": "[500, Inf)"},
  "xyp_t01bazzbz": {"1": "(0, 220)", "2": "[220,350)", "3": "[350,450)", "4": "[450,500)", "5": "[500, Inf)"},
  "xyp_t01bazzza": {"1": "(0,50)", "2": "[50,180)", "3": "[180,280)", "4": "[280,400)", "5": "[400, Inf)"},
  "xyp_t01bazzzc": {"1": "(0, 220)", "2": "[220,350)", "3": "[350,450)", "4": "[450,520)", "5": "[520, Inf)"},
  "xyp_t01bbzzaz": {"1": "(0,10)", "2": "[10,40)", "3": "[40,200)", "4": "[200, Inf)"},
  "xyp_t01bbzzba": {"1": "(0,10)", "2": "[10,30)", "3": "[30,150)", "4": "[150, Inf)"},
  "xyp_t01bbzzbc": {"1": "(0,5)", "2": "[5,50)", "3": "[50,160)", "4": "[160, Inf)"},
  "xyp_t01bbzzbz": {"1": "(0,5)", "2": "[5,25)", "3": "[25,50)", "4": "[50,150)", "5": "[150, Inf)"},
  "xyp_t01bbzzza": {"1": "(0,5)", "2": "[5,25)", "3": "[25,50)", "4": "[50,115)", "5": "[115, Inf)"},
  "xyp_t01ccbzbz": {"1": "(0,3)", "2": "[3,6)", "3": "[6,13)", "4": "[13, Inf)"},
  "xyp_t01ccbzzz": {"1": "(0,3)", "2": "[3,7)", "3": "[7,15)", "4": "[15, Inf)"},
  "xyp_t01ccczza": {"1": "(0,3)", "2": "[3, Inf)"},
  "xyp_t01ccezzz": {"1": "(0,3)", "2": "[3,5)", "3": "[5,34)", "4": "[34, Inf)"},
  "xyp_t01ccfzbc": {"1": "(0,2)", "2": "[2,10)", "3": "[10,20)", "4": "[20, Inf)"},
  "xyp_t01ccfzzz": {"1": "(0,6)", "2": "[6,22)", "3": "[22,56)", "4": "[56, Inf)"},
  "xyp_t01ccgzbc": {"1": "(0,2)", "2": "[2,4)", "3": "[4,33)", "4": "[33, Inf)"},
  "xyp_t01ccgzza": {"1": "(0,3)", "2": "[3,5)", "3": "[5,15)", "4": "[15, Inf)"},
  "xyp_t01ccgzzz": {"1": "(0,24)", "2": "[24,38)", "3": "[38,74)", "4": "[74, Inf)"},
  "xyp_t01cchzaz": {"1": "(0,3)", "2": "[3,6)", "3": "[6,16)", "4": "[16, Inf)"},
  "xyp_t01cchzbc": {"1": "(0,2)", "2": "[2,6)", "3": "[6,34)", "4": "[34, Inf)"},
  "xyp_t01cchzzc": {"1": "(0,2)", "2": "[2,44)", "3": "[44, Inf)"},
  "xyp_t01ccizza": {"1": "(0,2)", "2": "[2,3)", "3": "[3, Inf)"},
  "xyp_t01ccizzc": {"1": "(0,2)", "2": "[2,3)", "3": "[3,5)", "4": "[5, Inf)"},
  "xyp_t01ccjzaz": {"1": "(0,4)", "2": "[4,6)", "3": "[6, Inf)"},
  "xyp_t01ccjzbc": {"1": "(0,2)", "2": "[2,9)", "3": "[9,11)", "4": "[11,15)", "5": "[15, Inf)"},
  "xyp_t01cckzaz": {"1": "(0,3)", "2": "[3,6)", "3": "[6,11)", "4": "[11, Inf)"},
  "xyp_t01cckzzc": {"1": "(0,2)", "2": "[2,4)", "3": "[4,16)", "4": "[16,36)", "5": "[36, Inf)"},
  "xyp_t01cclzba": {"1": "(0,2)", "2": "[2,6)", "3": "[6,14)", "4": "[14, Inf)"},
  "xyp_t01cclzbc": {"1": "(0,2)", "2": "[2,9)", "3": "[9,14)", "4": "[14,31)", "5": "[31,50)", "6": "[50, Inf)"},
  "xyp_t01cclzzc": {"1": "(0,2)", "2": "[2,10)", "3": "[10,36)", "4": "[36,60)", "5": "[60, Inf)"},
  "xyp_t01cczzbc": {"1": "(0,2)", "2": "[2,9)", "3": "[9,14)", "4": "[14,27)", "5": "[27,49)", "6": "[49, Inf)"},
  "xyp_t01cczzzz": {"1": "(0,28)", "2": "[28,44)", "3": "[44,82)", "4": "[82, Inf)"},
  "xyp_t01defzbz": {"1": "(0,2)", "2": "[2,5)", "3": "[5,13)", "4": "[13, Inf)"},
  "xyp_t01degzbc": {"1": "(0,1)", "2": "[1,5)", "3": "[5,10)", "4": "[10, Inf)"},
  "xyp_t01degzzc": {"1": "(0,1)", "2": "[1,7)", "3": "[7,11)", "4": "[11, Inf)"},
  "xyp_t01dejzbc": {"1": "(0,1)", "2": "[1,5)", "3": "[5,8)", "4": "[8, Inf)"},
  "xyp_t01dejzbz": {"1": "(0,1)", "2": "[1,5)", "3": "[5,8)", "4": "[8, Inf)"},
  "xyp_t01dejzzc": {"1": "(0,2)", "2": "[2,7)", "3": "[7,9)", "4": "[9, Inf)"},
  "xyp_t01dekzbc": {"1": "(0,1)", "2": "[1,8)", "3": "[8,12)", "4": "[12, Inf)"},
  "xyp_t01dekzzc": {"1": "(0,2)", "2": "[2,10)", "3": "[10,14)", "4": "[14, Inf)"},
  "xyp_t01dekzzz": {"1": "(0,11)", "2": "[11,15)", "3": "[15, Inf)"},
  "xyp_t01delzbc": {"1": "(0,1)", "2": "[1,3)", "3": "[3,8)", "4": "[8,12)", "5": "[12, Inf)"},
  "xyp_t01delzzc": {"1": "(0,2)", "2": "[2,3)", "3": "[3,11)", "4": "[11,17)", "5": "[17, Inf)"},
  "xyp_t01aazfbc": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aazfzc": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aazfzz": {"1": "(0,1000)", "2": "[1000,2000)", "3": "[2000, Inf)"},
  "xyp_t01aazgzc": {"1": "(0, 400)", "2": "[400,1000)", "3": "[1000, Inf)"},
  "xyp_t01aazhaz": {"1": "(0,1000)", "2": "[1000,3000)", "3": "[3000, Inf)"},
  "xyp_t01aazhbz": {"1": "(0,1000)", "2": "[1000,4800)", "3": "[4800, Inf)"},
  "xyp_t01aazhzc": {"1": "(0,1000)", "2": "[1000,4800)", "3": "[4800, Inf)"},
  "xyp_t01aazhzz": {"1": "(0,1200)", "2": "[1200,4800)", "3": "[4800, Inf)"},
  "xyp_t01abzfba": {"1": "(0, 200)", "2": "[200,1300)", "3": "[1300, Inf)"},
  "xyp_t01abzfbz": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abzfzc": {"1": "(0,50)", "2": "[50,450)", "3": "[450, Inf)"},
  "xyp_t01abzfzz": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01abzgza": {"1": "(0, 600)", "2": "[600,1300)", "3": "[1300, Inf)"},
  "xyp_t01abzgzc": {"1": "(0,50)", "2": "[50,100)", "3": "[100, Inf)"},
  "xyp_t01aczezz": {"1": "(0,1000)", "2": "[1000,32000)", "3": "[32000, Inf)"},
  "xyp_t01aczfba": {"1": "(0,3000)", "2": "[3000,14000)", "3": "[14000, Inf)"},
  "xyp_t01aczfbc": {"1": "(0,1000)", "2": "[1000,31000)", "3": "[31000, Inf)"},
  "xyp_t01aczfbz": {"1": "(0,6000)", "2": "[6000,62000)", "3": "[62000, Inf)"},
  "xyp_t01aczfza": {"1": "(0,3000)", "2": "[3000,17000)", "3": "[17000, Inf)"},
  "xyp_t01aczfzc": {"1": "(0,4000)", "2": "[4000,50000)", "3": "[50000, Inf)"},
  "xyp_t01aczfzz": {"1": "(0,6000)", "2": "[6000,62000)", "3": "[62000, Inf)"},
  "xyp_t01aczgbc": {"1": "(0,2000)", "2": "[2000,36000)", "3": "[36000, Inf)"},
  "xyp_t01aczgbz": {"1": "(0,12000)", "2": "[12000,38000)", "3": "[38000, Inf)"},
  "xyp_t01aczgza": {"1": "(0,1000)", "2": "[1000,18000)", "3": "[18000, Inf)"},
  "xyp_t01aczgzc": {"1": "(0,12000)", "2": "[12000,50000)", "3": "[50000, Inf)"},
  "xyp_t01aczgzz": {"1": "(0,12000)", "2": "[12000,92000)", "3": "[92000, Inf)"},
  "xyp_t01aczhbc": {"1": "(0,4000)", "2": "[4000,42000)", "3": "[42000, Inf)"},
  "xyp_t01aczhzc": {"1": "(0,4000)", "2": "[4000,58000)", "3": "[58000, Inf)"},
  "xyp_t01adzfbz": {"1": "(0, 200)", "2": "[200,1500)", "3": "[1500, Inf)"},
  "xyp_t01adzfzc": {"1": "(0, 500)", "2": "[500,1800)", "3": "[1800, Inf)"},
  "xyp_t01adzgbc": {"1": "(0, 600)", "2": "[600,1600)", "3": "[1600, Inf)"},
  "xyp_t01adzgzc": {"1": "(0, 600)", "2": "[600,1700)", "3": "[1700, Inf)"},
  "xyp_t01cczezz": {"1": "(0,3)", "2": "[3,25)", "3": "[25, Inf)"},
  "xyp_t01cczfbz": {"1": "(0,6)", "2": "[6,18)", "3": "[18,32)", "4": "[32, Inf)"},
  "xyp_t01cczfza": {"1": "(0,3)", "2": "[3,6)", "3": "[6,13)", "4": "[13, Inf)"},
  "xyp_t01cczfzc": {"1": "(0,2)", "2": "[2,11)", "3": "[11,41)", "4": "[41, Inf)"},
  "xyp_t01cczgbc": {"1": "(0,2)", "2": "[2,13)", "3": "[13,27)", "4": "[27, Inf)"},
  "xyp_t01cczgza": {"1": "(0,3)", "2": "[3,15)", "3": "[15, Inf)"},
  "xyp_t01cczgzc": {"1": "(0,2)", "2": "[2,4)", "3": "[4,17)", "4": "[17,41)", "5": "[41, Inf)"},
  "xyp_t01cczgzz": {"1": "(0,8)", "2": "[8,28)", "3": "[28,50)", "4": "[50, Inf)"},
  "xyp_t01cczhbc": {"1": "(0,2)", "2": "[2,3)", "3": "[3,31)", "4": "[31, Inf)"},
  "xyp_t01cczhza": {"1": "(0,3)", "2": "[3,8)", "3": "[8,15)", "4": "[15, Inf)"},
  "xyp_t01cczhzc": {"1": "(0,25)", "2": "[25,43)", "3": "[43, Inf)"},
  "xyp_t01cczhzz": {"1": "(0,12)", "2": "[12,36)", "3": "[36,64)", "4": "[64, Inf)"},
  "xyp_t01dezgzz": {"1": "(0,1)", "2": "[1,6)", "3": "[6,12)", "4": "[12, Inf)"},
  "xyp_t01dezhba": {"1": "(0,1)", "2": "[1,2)", "3": "[2,3)", "4": "[3, Inf)"},
  "xyp_t01dezhbc": {"1": "(0,1)", "2": "[1,8)", "3": "[8, Inf)"},
  "xyp_t02acczza_acczzz": undefined,
  "xyp_t02acezba_acezbz": undefined,
  "xyp_t02acfzba_acfzbz": undefined,
  "xyp_t02acfzbc_acfzbz": undefined,
  "xyp_t02acfzza_acfzzz": undefined,
  "xyp_t02acgzba_acgzbz": undefined,
  "xyp_t02acgzbc_acgzbz": undefined,
  "xyp_t02achzbc_achzbz": undefined,
  "xyp_t02achzza_achzzz": undefined,
  "xyp_t02achzzc_achzzz": undefined,
  "xyp_t02acizba_acizbz": undefined,
  "xyp_t02acizbc_acizbz": undefined,
  "xyp_t02acjzbc_acjzbz": undefined,
  "xyp_t02acjzzc_acjzzz": undefined,
  "xyp_t02ackzbc_ackzbz": undefined,
  "xyp_t02ackzza_ackzzz": undefined,
  "xyp_t02ackzzc_ackzzz": undefined,
  "xyp_t02aclzba_aclzbz": undefined,
  "xyp_t02aclzbc_aclzbz": undefined,
  "xyp_t02aclzza_aclzzz": undefined,
  "xyp_t02aclzzc_aclzzz": undefined,
  "xyp_t02aczzza_aczzzz": undefined,
  "xyp_t02acdzza_aczzza": undefined,
  "xyp_t02acezza_aczzza": undefined,
  "xyp_t02acfzbc_aczzbc": undefined,
  "xyp_t02acfzzc_aczzzc": undefined,
  "xyp_t02acizba_aczzba": undefined,
  "xyp_t02acizza_aczzza": undefined,
  "xyp_t02ccezba_ccezbz": undefined,
  "xyp_t02ccfzba_ccfzbz": undefined,
  "xyp_t02ccfzbc_ccfzbz": undefined,
  "xyp_t02ccgzba_ccgzbz": undefined,
  "xyp_t02ccgzbc_ccgzbz": undefined,
  "xyp_t02ccgzza_ccgzzz": undefined,
  "xyp_t02ccgzzc_ccgzzz": undefined,
  "xyp_t02cchzba_cchzbz": undefined,
  "xyp_t02cchzbc_cchzbz": undefined,
  "xyp_t02cchzza_cchzzz": undefined,
  "xyp_t02cchzzc_cchzzz": undefined,
  "xyp_t02cckzbc_cckzbz": undefined,
  "xyp_t02cclzba_cclzbz": undefined,
  "xyp_t02cczzba_cczzbz": undefined,
  "xyp_t02cczzza_cczzzz": undefined,
  "xyp_t02cczzzc_cczzzz": undefined,
  "xyp_t02ccezba_cczzba": undefined,
  "xyp_t02ccezza_cczzza": undefined,
  "xyp_t02ccezzc_cczzzc": undefined,
  "xyp_t02ccfzba_cczzba": undefined,
  "xyp_t02ccfzbc_cczzbc": undefined,
  "xyp_t02ccfzza_cczzza": undefined,
  "xyp_t02ccfzzc_cczzzc": undefined,
  "xyp_t02ccizbc_cczzbc": undefined,
  "xyp_t02ccizza_cczzza": undefined,
  "xyp_t02ccizzc_cczzzc": undefined,
  "xyp_t02dezezz_dezzzz": {"1": "(0,1)", "2": "[1, Inf)"},
  "xyp_t02dezfzz_dezzzz": {"1": "(0,1)", "2": "[1, Inf)"},
  "xyp_t02dezgzz_dezzzz": {"1": "(0,1)", "2": "[1, Inf)"},
  "xyp_t03td045": {"1": "(0,1)", "2": "[1,3)", "3": "[3, Inf)"},
  "xyp_t03td053": {"1": "(0,1)", "2": "[1,2)", "3": "[2,3)", "4": "[3, Inf)"},
  "xyp_t03td061": {"1": "(0,1)", "2": "[1,2)", "3": "[2,3)", "4": "[3, Inf)"},
  "xyp_t03td111": {"1": "(0,1)", "2": "[1,3)", "3": "[3, Inf)"},
  "xyp_t03td115": {"1": "(0,1)", "2": "[1,2)", "3": "[2,3)", "4": "[3, Inf)"},
  "xyp_t03td148": {"1": "(0,1)", "2": "[1,2)", "3": "[2,3)", "4": "[3, Inf)",},
  "xyp_t0400002": undefined,
  "xyp_t0400003": undefined,
  "xyp_t0400004": undefined,
  "xyp_cpl0052": {"1": "(0,1)", "2": "[1,2)", "3": "[2,4)", "4": "[4, Inf)"},
  "xyp_cpl0053": {"1": "(0,1)", "2": "[1,2)", "3": "[2,4)", "4": "[4,10)", "5": "[10, Inf)"},
  "xyp_cpl0069": {"1": "(0,2)", "2": "[2,4)", "3": "[4,10)", "4": "[10, Inf)"},
  "xyp_cpl0054": {"1": "(0,14)", "2": "[14, Inf)"},
  "xyp_cpl0055": {
    "1": "(0,3]",
    "2": "(3,5]",
    "3": "(5,8]",
    "4": "(8,10]",
    "5": "(10,15]",
    "6": "(15,20]",
    "7": "(20,30]",
    "8": "(30,50]",
    "9": "(50,100]",
    "10": "(100,200]",
    "11": "(200,500]",
    "12": "(500,1000]",
    "13": "(1000,Inf)"
  },
  "xyp_cpl0056": {"1": "(0,5)", "2": "[5,20)", "3": "[20,35)", "4": "[35, Inf)"},
  "xyp_cpl0057": {"1": "(0,1)", "2": "[1, Inf)"},
  "xyp_cpl0058": {
    "1": "(0,3]",
    "2": "(3,5]",
    "3": "(5,8]",
    "4": "(8,10]",
    "5": "(10,15]",
    "6": "(15,20]",
    "7": "(20,30]",
    "8": "(30,50]",
    "9": "(50,100]",
    "10": "(100,200]",
    "11": "(200,500]",
    "12": "(500,1000]",
    "13": "(1000,Inf)"
  },
  "xyp_cpl0059": {"1": "(0,10)", "2": "[10, Inf)"},
  "xyp_cpl0060": {"1": "(0,5)", "2": "[5, Inf)"},
  "xyp_cpl0061": {
    "1": "(0,3]",
    "2": "(3,5]",
    "3": "(5,8]",
    "4": "(8,10]",
    "5": "(10,15]",
    "6": "(15,20]",
    "7": "(20,30]",
    "8": "(30,50]",
    "9": "(50,100]",
    "10": "(100,200]",
    "11": "(200,500]",
    "12": "(500,1000]",
    "13": "(1000,Inf)"
  },
  "xyp_cpl0062": {"1": "(0,1)", "2": "[1,2)", "3": "[2,36)", "4": "[36, Inf)"},
  "xyp_cpl0082": undefined,
  "xyp_cpl0083": undefined,
  "xyp_cpl0081": undefined,
  "xyp_model_score_high": undefined,
  "xyp_model_score_mid": undefined,
  "xyp_model_score_low": undefined,
}
