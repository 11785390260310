export default {
  // 报告评分
  formData1: state => {
    return [
      {
        current_org_count: state.current_org_count || '-',
        current_order_count: state.current_order_count || '-',
        current_order_amt: state.current_order_amt || '-',
        current_order_lend_amt: state.current_order_lend_amt || '-',
        order_count: state.order_count || '-',
        member_count: state.member_count || '-',
        debt_amount: state.debt_amount || '-'
      },
    ];
  },
};
