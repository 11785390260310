export default {
  // 报告评分
  formData1: state => {
    // xyp_t01ccbzzz	最近7天_笔数_求和
    // xyp_t01ccezzz	最近30天_笔数_求和
    // xyp_t01ccfzzz	最近90天_笔数_求和
    // xyp_t01ccgzzz	最近180天_笔数_求和
    // xyp_cpl0048	最近7天_交易失败次数>0的机构数
    // xyp_cpl0049	最近14天_交易失败次数>0的机构数
    // xyp_cpl0050	最近21天_交易失败次数>0的机构数
    // xyp_cpl0051	最近30天_交易失败次数>0的机构数
    // xyp_t01acbzzz	最近7天_交易⾦额_求和
    // xyp_t01acczzz	最近14天_交易⾦额_求和
    // xyp_t01acdzzz	最近21天_交易⾦额_求和
    // xyp_t01acezzz	最近30天_交易⾦额_求和
    // xyp_t01acgzzz	最近180天_交易⾦额_求和
    // xyp_cpl0070	最近1天贷款机构数
    // xyp_cpl0009	最近7天贷款机构数
    // xyp_cpl0010	最近14天贷款机构数
    // xyp_cpl0063	最近21天贷款机构数
    // xyp_cpl0011	最近30天贷款机构数
    // xyp_cpl0012	最近90天贷款机构数
    // xyp_cpl0013	最近180天贷款机构数
    // xyp_cpl0017	最近1天还款成功笔数
    // xyp_cpl0019	最近7天还款成功笔数
    // xyp_cpl0021	最近14天还款成功笔数
    // xyp_cpl0064	最近21天还款成功笔数
    // xyp_cpl0023	最近30天还款成功笔数
    // xyp_cpl0025	最近90天还款成功笔数
    // xyp_cpl0027	最近180天还款成功笔数
    // xyp_cpl0033	最近1天还款成功总⾦额
    // xyp_cpl0035	最近7天还款成功总⾦额
    // xyp_cpl0037	最近14天还款成功总⾦额
    // xyp_cpl0067	最近21天还款成功总⾦额
    // xyp_cpl0039	最近30天还款成功总⾦额
    // xyp_cpl0041	最近90天还款成功总⾦额
    // xyp_cpl0043	最近180天还款成功总⾦额
    // xyp_cpl0016	最近1天交易失败笔数
    // xyp_cpl0018	最近7天交易失败笔数
    // xyp_cpl0020	最近14天交易失败笔数
    // xyp_cpl0022	最近30天交易失败笔数
    // xyp_cpl0024	最近90天交易失败笔数
    // xyp_cpl0026	最近180天交易失败笔数
    // xyp_cpl0032	最近1天交易失败总⾦额
    // xyp_cpl0034	最近7天交易失败总⾦额
    // xyp_cpl0036	最近14天交易失败总⾦额
    // xyp_cpl0066	最近21天交易失败总⾦额
    // xyp_cpl0038	最近30天交易失败总⾦额
    // xyp_cpl0040	最近90天交易失败总⾦额
    // xyp_cpl0042	最近180天交易失败总⾦额
    // xyp_cpl0028	最近1天是否发⽣过逾期
    // xyp_cpl0029	最近7天是否发⽣过逾期
    // xyp_cpl0030	最近14天是否发⽣过逾期
    // xyp_cpl0031	最近30天是否发⽣过逾期
    return [
      {
        // 笔数_求和
        name: "笔数_求和",
        n1: "-",n7: state['xyp_t01ccbzzz'],n14: "-",n21: "-",
        n30: state['xyp_t01ccezzz'],n90: state['xyp_t01ccfzzz'],n180: state['xyp_t01ccgzzz']
      },
      {
        // 交易失败次数>0的机构数
        name: "交易失败次数>0的机构数",
        n1: "-",n7: state['xyp_cpl0048'],n14: state['xyp_cpl0049'],n21: state['xyp_cpl0050'],
        n30: state['xyp_cpl0051'],n90:  "-",n180:  "-"
      },
      {
        // 交易⾦额_求和
        name: "交易⾦额_求和",
        n1: "-",n7: state['xyp_t01acbzzz'],n14: state['xyp_t01acczzz'],n21: state['xyp_t01acdzzz'],
        n30: state['xyp_t01acezzz'],n90: "-",n180: state['xyp_t01acgzzz']
      },
      {
        // 贷款机构数
        name: "贷款机构数",
        n1: state['xyp_cpl0070'],n7: state['xyp_cpl0009'],n14: state['xyp_cpl0010'],n21: state['xyp_cpl0063'],
        n30: state['xyp_cpl0011'],n90: state['xyp_cpl0012'],n180: state['xyp_cpl0013']
      },
      {
        // 交易失败笔数
        name: "交易失败笔数",
        n1: state['xyp_cpl0016'],n7: state['xyp_cpl0018'],n14: state['xyp_cpl0020'],n21: "-",
        n30: state['xyp_cpl0022'],n90: state['xyp_cpl0024'],n180: state['xyp_cpl0026']

      },
      {
        // 交易失败总⾦额
        name: "交易失败总⾦额",
        n1: "xyp_cpl0032",n7: state['xyp_cpl0034'],n14: state['xyp_cpl0036'],n21: state['xyp_cpl0066'],
        n30: state['xyp_cpl0038'],n90: state['xyp_cpl0040'],n180: state['xyp_cpl0042']
      },

      {
        // 还款笔数
        name: "还款笔数",
        n1: state['xyp_cpl0017'],n7: state['xyp_cpl0019'],n14: state['xyp_cpl0021'],n21: state['xyp_0064'],
        n30: state['xyp_cpl0023'],n90: state['xyp_cpl0025'],n180: state['xyp_cpl0027']
      },
      {
        // 还款总⾦额
        name: "还款总⾦额",
        n1: state['xyp_cpl0033'],n7: state['xyp_cpl0035'],n14: state['xyp_cpl0037'],n21: state['xyp_cpl0067'],
        n30: state['xyp_cpl0039'],n90: state['xyp_cpl0041'],n180: state['xyp_cpl0043']
      },
      {
        // 是否发⽣过逾期
        name: "是否发⽣过逾期",
        n1: state['xyp_cpl0028'],n7: state['xyp_cpl0029'],n14: state['xyp_cpl0030'],n21: state['xyp_0031'],
        n30: "-",n90: "-",n180: "-"
      },
    ];
  },
};
